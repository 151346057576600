import React, {useEffect, useRef} from 'react';
import {FullPage, Slide} from 'react-full-page';
import logo from '../public/image/logo.png';
import logoSvg from '../public/svg/logo.svg';
import logoDupont from '../public/image/dupont-logo.png';
import logoLg from '../public/image/logo-lg.png';
import logoHydranautics from '../public/image/logo-hydranautics.png';
import logoToray from '../public/image/logo-toray.png';
import dupontProduct from '../public/image/dupont-product.png';
import lgProduct from '../public/image/lg-product.png';
import hydranauticsProduct from '../public/image/hydranautics-product.png';
import torayProduct from '../public/image/toray-product.png';
import pdfFile from './sats-co.pdf'

import {useState} from 'react';
import {
    FullpageContainer,
    FullpageSection,
} from '@shinyongjun/react-fullpage';
import {NavLink} from "react-router-dom";
import Footer from "../component/Footer";


export default function Home() {
    const [activeIndex, setActiveIndex] = useState(0);
    const [activeIndex1, setActiveIndex1] = useState(0);
    const [footer, setFooter] = useState(true);
    const [menu, setMenu] = useState(true);
    const refs = [
        useRef(),
        useRef(),
        useRef(),
        useRef(),
        useRef(),
        useRef(),
    ];
    const [height, setHeight] = useState(0)

    const refHeight = useRef(null)

    useEffect(() => {
        setHeight(refHeight?.current?.clientWidth)
    })

    useEffect(() => {
        setTimeout(() => {
            if (activeIndex > 0 && activeIndex1 > activeIndex) {
                const ref = refs[activeIndex - 1];
                ref.current?.scrollIntoView({behavior: 'smooth'});

            }
            switch (activeIndex) {
                case 0 :
                    setActiveIndex1(0)
                    break;
                case 1 :
                    setActiveIndex1(1)
                    break;
                case 2 :
                    setActiveIndex1(2)
                    break;
                case 3 :
                    setActiveIndex1(3)
                    break;
                case 4 :
                    setActiveIndex1(4)
                    break;
                case 5 :
                    setActiveIndex1(5)
                    break;
                case 6 :
                    setActiveIndex1(6)
                    break;
                case 7 :
                    setActiveIndex1(7)
                    break;

            }
        }, 500)

    }, [activeIndex])
    return (
        <div className="main-sec flex justify-end">
            <div className="lg:hidden block">
                <div onClick={() => {
                    setFooter(false)
                }} className="footer-phone-btn fixed">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="50"
                        height="50"
                        version="1.1"
                        viewBox="0 0 50 50"
                        xmlSpace="preserve"
                    >
                        <path fill="none" d="M0 0H50V50H0z"></path>
                        <path
                            fill="none"
                            stroke="#fff"
                            strokeMiterlimit="10"
                            strokeWidth="3"
                            d="M30.217 35.252s4.049-2.318 5.109-2.875c1.057-.559 2.152-.7 2.817-.294 1.007.616 9.463 6.241 10.175 6.739.712.499 1.055 1.924.076 3.32-.975 1.396-5.473 6.916-7.379 6.857-1.909-.062-9.846-.236-24.813-15.207C1.238 18.826 1.061 10.887 1 8.978.939 7.07 6.459 2.571 7.855 1.595c1.398-.975 2.825-.608 3.321.078.564.781 6.124 9.21 6.736 10.176.419.66.265 1.761-.294 2.819-.556 1.06-2.874 5.109-2.874 5.109s1.634 2.787 7.16 8.312c5.527 5.526 8.313 7.163 8.313 7.163z"
                        ></path>
                    </svg>
                </div>

                <div onClick={() => {
                    setMenu(false)
                }} className="footer-phone-btn-2 fixed">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                        <g data-name="1">
                            <path fill="#fff"
                                  d="M441.13 166.52h-372a15 15 0 110-30h372a15 15 0 010 30zM441.13 279.72h-372a15 15 0 110-30h372a15 15 0 010 30zM441.13 392.92h-372a15 15 0 110-30h372a15 15 0 010 30z"></path>
                        </g>
                    </svg>
                </div>

                <div onClick={() => {
                    setMenu(true)
                }} className={menu ? "over-lay" : "over-lay open"}></div>
                <div
                    className={menu ? "btn-control-page dis-block-imp fixed " : "btn-control-page dis-block-imp fixed open"}>
                    <div className="w-full lg:block lg:hidden pt-[3rem] pr-[3rem] pb-[2rem] text-left">
                        <img onClick={() => {
                            setActiveIndex(0)
                        }} className="mr-auto ml-10 cursor-pointer" src={logo} width={160} height={132}
                             alt="سفير افاق تجارت سبز"
                             title="سفير افاق تجارت سبز"/>
                    </div>
                    <ul className="relative z-10">

                        <li className={activeIndex == 1 ? "selected" : ""} onClick={() => {
                            setActiveIndex(1)
                            setMenu(true)
                        }}>درباره آفاق
                        </li>
                        <li className={activeIndex == 2 ? "selected" : ""} onClick={() => {
                            setActiveIndex(2)
                            setMenu(true)
                        }}>فیلتر ممبران
                        </li>
                        <li className={activeIndex == 3 ? "selected" : ""} onClick={() => {
                            setActiveIndex(3)
                            setMenu(true)
                        }}>DuPont
                        </li>
                        <li className={activeIndex == 4 ? "selected" : ""} onClick={() => {
                            setActiveIndex(4)
                            setMenu(true)
                        }}>LGChem
                        </li>
                        <li className={activeIndex == 5 ? "selected" : ""} onClick={() => {
                            setActiveIndex(5)
                            setMenu(true)
                        }}>HYDRANAUTICS
                        </li>
                        <li className={activeIndex == 6 ? "selected" : ""} onClick={() => {
                            setActiveIndex(6)
                            setMenu(true)
                        }}>TORAY
                        </li>
                        <li className="download-btn">
                            <NavLink href={pdfFile} download="sats-co"
                                     target="_blank"
                                     rel="noreferrer" title=" دانلود کاتالوگ">
                                دانلود کاتالوگ
                            </NavLink>
                        </li>
                    </ul>
                </div>


                <div onClick={() => {
                    setFooter(true)
                }} className={footer ? "over-lay" : "over-lay open"}></div>
                <div className={footer ? "footer-box fixed close-this" : "footer-box fixed"}>
                   <img className="mx-auto w-[100px] mb-5" src={logoSvg} alt="سفير افاق تجارت سبز"
                        title="سفير افاق تجارت سبز" />

                    <p className="font-[300] text-[14px] text-[#437B9F]">
                        شرکت بازرگانی "سفیر آفاق تجارت سبز" با چندین سال تجربه در زمينه واردات و صادرات،
                        با اتكا به تخصص همكاران در بخش های مختلف، انواع ممبران از برند ‌های
                        "FILMTEC"،"LG" ، HYDRANAUTICS" و "TORAY" را با گواهی اصالت کالا در اختیار مصرف
                        کنندگان و صنعتگران عزيز كشور قرار ميدهد.
                    </p>
                    <div className="w-full flex flex-wrap mt-5">
                        <div className="lg:w-6/12 w-full lg:mb-0 mb-5 lg:pl-5 pl-0">
                            <table className="w-full ">
                                <tr className="w-full ">
                                    <td className="font-[600] text-[12px] text-[#437B9F] lg:pb-0 pb-6"> هماهنگی و فروش
                                        محصول
                                    </td>
                                    <td className="dir-ltr text-left lg:pb-0 pb-6">
                                        <NavLink className="font-[300] text-[12px] text-[#437B9F]" to="tel:982188728522"
                                                 title="982188728522">+98 2188728522</NavLink>
                                        {" - "}
                                        <NavLink className="font-[300] text-[12px] text-[#437B9F]" tel="09127584165"
                                                 title="09127584165">0912 758 4165</NavLink>
                                        {" - "}
                                        <NavLink className="font-[300] text-[12px] text-[#437B9F]" tel="09128541143"
                                                 title="09128541143">0912 854 1143</NavLink>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="font-[600] text-[12px] text-[#437B9F]"> خدمات پس از فروش</td>
                                    <td className="text-left dir-ltr">
                                        <NavLink className="font-[300] text-[12px] text-[#437B9F]" to="tel:02188728623"
                                                 title="02188728623">021 88728623</NavLink>
                                    </td>
                                </tr>
                            </table>
                        </div>
                        <div className="lg:w-6/12 w-full lg:pr-5 pr-0">
                            <table>
                                <tr>
                                    <td className="font-[600] text-[12px] text-[#437B9F] lg:pb-0 pb-6"> نشانی</td>
                                    <td className="font-[300] text-[12px] text-[#437B9F] lg:pb-0 pb-6 text-left dir-ltr">
                                        تهران، میدان آرژانتین، خیابان احمد قصیر، بین کوچه 11 و 13، پلاک 38، واحد 3
                                    </td>
                                </tr>
                                <tr>
                                    <td className="font-[600] text-[12px] text-[#437B9F]">ایمیل</td>
                                    <td className="text-left dir-ltr">
                                        <NavLink className="font-[300] text-[12px] text-[#437B9F]"
                                                 to="mailto:info@sats-co.com"
                                                 title="info@sats-co.com">info@sats-co.com</NavLink>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

            <div
                className={activeIndex != 0 && activeIndex != 7 ? "ml-0 pl-[5rem] z-10 mr-auto fixed opacity-100 transition-all show-this" : "ml-0 pl-[5rem] z-10 mr-auto fixed show-this hide-this"}>
                <div style={{width:height}}
                    className="left-0 right-[76px] resp-2 right-[4rem] bottom-0 lg:mr-16 mr-6 lg:ml-0 ml-6 tb-lg-0 lg:mt-[7rem] mt-5 flex flex-col justify-between mb-10 fixed">
                    <div  className="w-full lg:block hidden footer-box">
                        <p className="font-[300] text-[14px] text-[#437B9F] text-justify f-13-tab">
                            شرکت بازرگانی "سفیر آفاق تجارت سبز" با چندین سال تجربه در زمينه واردات و صادرات،
                            با اتكا به تخصص همكاران در بخش های مختلف، انواع ممبران از برند ‌های
                            "FILMTEC"،"LG" ، HYDRANAUTICS" و "TORAY" را با گواهی اصالت کالا در اختیار مصرف
                            کنندگان و صنعتگران عزيز كشور قرار ميدهد.
                        </p>
                        <div className="w-full flex mt-5">
                            <div className="w-6/12 pl-5">
                                <table className="w-full m-0">
                                    <tbody>
                                    <tr className="w-full ">
                                        <td className="font-[600] text-[13px] text-[#437B9F]"> هماهنگی و فروش </td>
                                        <td className="dir-ltr text-left">
                                            <NavLink className="font-[300] text-[13px] text-[#437B9F]"
                                                     to="tel:982188728522"
                                                     title="982188728522">+98 2188728522</NavLink>
                                            {" - "}
                                            <NavLink className="font-[300] text-[13px] text-[#437B9F]" tel="09127584165"
                                                     title="09127584165">0912 758 4165</NavLink>
                                            {" - "}
                                            <NavLink className="font-[300] text-[13px] text-[#437B9F]" tel="09128541143"
                                                     title="09128541143">0912 854 1143</NavLink>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="font-[600] text-[13px] text-[#437B9F]"> خدمات پس از فروش</td>
                                        <td className="text-left dir-ltr">
                                            <NavLink className="font-[300] text-[13px] text-[#437B9F]"
                                                     to="tel:02188728623"
                                                     title="02188728623">021 88728623</NavLink>
                                        </td>
                                    </tr>
                                    </tbody>

                                </table>
                            </div>
                            <div className="w-6/12 pr-5">
                                <table className="ml-0 mr-auto">
                                    <tbody>
                                    <tr>
                                        <td className="font-[600] text-[13px] text-[#437B9F]"> نشانی</td>
                                        <td className="font-[300] text-[13px] text-[#437B9F] text-left dir-ltr">
                                            تهران، میدان آرژانتین، خیابان احمد قصیر، بین کوچه 11 و 13، پلاک 38، واحد 3
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="font-[600] text-[13px] text-[#437B9F]">ایمیل</td>
                                        <td className="text-left dir-ltr">
                                            <NavLink className="font-[300] text-[13px] text-[#437B9F]"
                                                     to="mailto:info@sats-co.com"
                                                     title="info@sats-co.com">info@sats-co.com</NavLink>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex items-center lg:h-[100vh] h-auto">
                    <div className="w-full lg:block hidden border-l-main h-fit-ct text-left">
                        <img onClick={() => {
                            setActiveIndex(0)
                        }} className="mr-auto ml-10 cursor-pointer" src={logo} width={160} height={132}
                             alt="سفير افاق تجارت سبز"
                             title="سفير افاق تجارت سبز"/>
                        <ul className="btn-control-page z-10">

                            <li className={activeIndex == 1 ? "selected" : ""} onClick={() => {
                                setActiveIndex(1)
                            }}>درباره آفاق
                            </li>
                            <li className={activeIndex == 2 ? "selected" : ""} onClick={() => {
                                setActiveIndex(2)
                            }}>فیلتر ممبران
                            </li>
                            <li className={activeIndex == 3 ? "selected" : ""} onClick={() => {
                                setActiveIndex(3)
                            }}>DuPont
                            </li>
                            <li className={activeIndex == 4 ? "selected" : ""} onClick={() => {
                                setActiveIndex(4)
                            }}>LGChem
                            </li>
                            <li className={activeIndex == 5 ? "selected" : ""} onClick={() => {
                                setActiveIndex(5)
                            }}>HYDRANAUTICS
                            </li>
                            <li className={activeIndex == 6 ? "selected" : ""} onClick={() => {
                                setActiveIndex(6)
                            }}>TORAY
                            </li>
                            <li className="download-btn">
                                <NavLink download="sats-co"
                                         target="_blank"
                                         rel="noreferrer"  to={pdfFile} title=" دانلود کاتالوگ">
                                    دانلود کاتالوگ
                                </NavLink>
                            </li>
                        </ul>
                    </div>

                </div>

            </div>

            <FullpageContainer
                activeIndex={activeIndex}
                setActiveIndex={setActiveIndex}
            >
                <FullpageSection>
                    <div className="center-logo-container relative">
                        <img className="m-auto logo-phone" src={logo} width={400} title="سفير افاق تجارت سبز" alt="سفير افاق تجارت سبز"/>
                        <h1 className="hidden">سفیر آفاق تجارت سبز</h1>
                        <p className="hidden"> SAFIR AFAGH TEJARAT SABz</p>
                        <div className="group  w-fit mx-auto mb-[70px] flex">
                            <svg onClick={() => {
                                setActiveIndex(1)
                            }} className="mx-auto animate-arr" width="54" height="74" viewBox="0 0 54 74"
                                 fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect x="0.321429" y="0.321429" width="53.3571" height="72.6429" rx="12.5357"
                                      stroke="#437B9F" strokeOpacity="0.2" strokeWidth="0.642857"/>
                                <rect x="7.71317" y="7.72098" width="38.5714" height="57.8571" rx="9" stroke="#437B9F"
                                      strokeOpacity="0.5" strokeWidth="1.28571"/>
                                <path d="M19.5 38.1484L27 45.6484L34.5 38.1484" stroke="#437B9F" strokeOpacity="0.7"
                                      strokeWidth="1.28571" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M19.5 27.6484L27 35.1484L34.5 27.6484" stroke="#437B9F" strokeOpacity="0.7"
                                      strokeWidth="1.28571" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                            <span
                                className="absolute inset-x-0 h-fit w-fit mx-auto bottom-[-100px] lg:mb-[250px] mb-[260px] lg:scale-0 scale-100
                                transition-all lg:rounded rounded-lg lg:bg-gray-800 bg-transparent  p-2 text-xs lg:text-white border-light-phone
                                text-[#717171] group-hover:scale-100">
                                برای دیدن ادامه سایت اسکرول دهید
                            </span>

                        </div>
                    </div>
                </FullpageSection>
                <FullpageSection>
                    <div ref={refs[0]} className="w-screen lg:mx-[5rem] mx-0 relative">
                        <div className="lg:flex block lg:h-[100vh] h-auto">
                            <div ref={refHeight}
                                className="lg:w-8/12 h-s w-allow resp-2  lg:mr-16 mr-6 lg:ml-0 ml-6 lg:mt-[7rem] tb-lg-0 mt-5 flex flex-col justify-between mb-10"
                            >
                                <div className="header-box">
                                    <div className="relative w-fit">
                                        <h2 className={activeIndex1 != 1 ? "lg:font-[700] font-[600] w-fit lg:text-[1.9rem] text-[1.1rem] text-[#3C3C3C]"
                                            : "lg:font-[700] font-[600] w-fit lg:text-[1.9rem] lg:text-[1.1rem] text-[1.3rem] text-[#3C3C3C] animate-rtl-2"}>شرکت
                                            "<span className="gilroy">SAFIR AFAGH TEJARAT SABZ</span>"</h2>
                                        <span
                                            className={activeIndex1 != 1 ? "animate-ltr" : "animate-ltr run-animate"}></span>
                                    </div>
                                    <div
                                        className={activeIndex1 != 1 ? "body-container  2xl:mt-5 md:mt-0 sm:mt-5 mt-2" : "body-container  2xl:mt-5 md:mt-0 sm:mt-5 mt-2 run-animate-2"}>
                                        <p className="font-[400] text-[1rem] text-[#717171] text-justify my-f-r">
                                            شركت سفير افاق تجارت سبز در ابتدا زير مجموعه ای از يک هولدينگ صنعتی بود كه
                                            از
                                            سال 1387
                                            با هدف واردات تخصصی انواع قطعات يدكی برای تامين نياز شركت مادر فعاليت خود را
                                            آغاز كرد. و
                                            در نهايت در سال 1397 و با توجه با نيازهای موجود در صنعت كشور اقدام به فعاليت
                                            تخصصی و
                                            جداگانه در زمينه تامين فيلترهای ممبران از شركت های مطرح و تراز اول جهان كرده
                                            و
                                            توانسته
                                            است نقشی به سزا در ميان وارد كنندگان عزيز كشور به دست آورد.
                                            <br/>
                                            <br/>
                                            <br/>
                                            شرکت سفیر آفاق تجارت سبز همواره در تلاش است كه در راستای قواعد تجارت مدرن در
                                            سطوح بین
                                            المللی ، داخلی و همچنين احترام به ذينفعان و رقبا، نقش مهم و سازنده ای در
                                            بهبود
                                            سطح کیفی
                                            صنعت كشور داشته باشد.
                                            نوآوری، خلاقيت و سرمايه گذاری در امور واردات و صادرات و همچنين برطرف كردن
                                            نياز
                                            های صنعت
                                            كشور و سایر شرکای تجاری همواره دستور كار مديريت و كاركنان اين مجموعه بوده
                                            است.
                                            <br/>
                                            <br/>
                                            <br/>

                                            اين مجموعه با انعطاف كامل در مقابل هر تغييری، اثربخشی خود در صحنه جهانی را
                                            به
                                            اثبات
                                            رسانده و با نوآوری هوشمندانه و اتكاء به شايستگی منابع انسانی كه همواره آن را
                                            بزرگترين
                                            سرمايه خود ميداند و همچنين توجه به منافع ذينفعان (مشتریان و کارکنان) و
                                            همچنین
                                            خدمات پس
                                            از فروش در تلاش است تا نام "سفیر آفاق تجارت سبز" ياداور اعتماد،صداقت و تخصص
                                            در
                                            بازارهای
                                            داخلی و خارجی باشد.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="lg:w-5/12 w-full  lg:pt-[5rem] pt-0 border-l-main text-left"> </div>
                        </div>
                    </div>
                </FullpageSection>
                <FullpageSection>
                    <div ref={refs[1]} className="w-screen lg:mx-[5rem] mx-0 relative">
                        <div className="lg:flex block lg:h-[100vh] h-auto">
                            <div ref={refHeight}
                                className="lg:w-8/12 h-s w-allow resp-2 lg:mr-16 mr-6 lg:ml-0 ml-6 lg:mt-[7rem] tb-lg-0 mt-5 flex flex-col justify-between mb-10"
                            >
                                <div className="header-box">
                                    <div className="relative w-fit">
                                        <h2 className={activeIndex1 != 2 ? "lg:font-[700] font-[600] w-fit lg:text-[1.9rem] text-[1.1rem] text-[#3C3C3C]"
                                            : "lg:font-[700] font-[600] w-fit lg:text-[1.9rem] lg:text-[1.1rem] text-[1.3rem] text-[#3C3C3C] animate-rtl-2"}>
                                            فیلتر ممبران چیست؟
                                        </h2>
                                        <span
                                            className={activeIndex1 != 2 ? "animate-ltr" : "animate-ltr run-animate"}></span>
                                    </div>
                                    <div
                                        className={activeIndex1 != 2 ? "body-container 2xl:mt-5 md:mt-0 sm:mt-5 mt-2" : "body-container 2xl:mt-5 md:mt-0 sm:mt-5 mt-2 run-animate-2"}>
                                        <p className="font-[400] text-[1rem] text-[#717171] text-justify my-f-r">
                                            فیلتر ممبران، اصلی ترین و مهم ترین قطعه در دستگاه های تصفیه آب صنعتی در حوزه
                                            فیلتراسیون و تصفیه به شمار می رود. فیلتر ممبران صنعتی یک غشای نیمه تراوا
                                            است، که تمامی آلودگی های شیمیایی، نیترات، نیتریت، آرسنیک را تا 99.6 درصد حذف
                                            می کند همچنین توانایی حذف املاح موجود در آب را تا 0.0001 میکرون دارا می باشد
                                            به همین دلیل گفته می شود این فیلتر موجب شیرین سازی آب و حذف مواد محلول موجود
                                            در آب می شود.
                                            همانطور که گفته شد فیلتر ممبران از چندین لایه غشاء نیمه تراوا تشکیل شده است
                                            بدین صورت که غشاء نیمه تراوا به صورت لایه های نازک به دور لوله ای پیچیده شده
                                            و ساختار آن به گونه ایست که تنها اجازه عبور برخی از مواد را می دهد، به همین
                                            دلیل در آب دارای املاح و آلاینده های مختلف تنها مولکول های آب اجازه عبور از
                                            فیلتر ممبران را داشته که نهایتا خروجی این فرآیند یا خروجی فیلتر ممبران آب
                                            تصفیه شده ای است که دارای استاندارد های لازم جهت نوشیدن یا استفاده در مصارف
                                            مختلف می باشد و در مقابل مایع تغلیظ شده ای که حاوی آلاینده های مختلف است به
                                            سمت مجاری فاضلاب هدایت شود.
                                            فشار کاری بر روی فیلتر ممبران صنعتی، به سختی آب و املاح موجود در آن بستگی
                                            دارد، بدین صورت که هر چه سختی آب بالاتر باشد، فشار اعمال شونده توسط آب نیز
                                            بر روی غشای ممبران بیشتر خواهد بود به همین دلیل میزان فشار ایجاد شده توسط
                                            پمپ در فرآیند تصفیه آب به وسیله فیلتر ممبران از اهمیت بالایی برخوردار است
                                            این میزان فشار با توجه به میزان غلظت آب تعیین می گردد، بدین معنا که هر چه
                                            میزان غلظت بیشتر باشد به فشار بیشتری برای عبور مایع از فیلتر ممبران
                                            نیازمندیم، فیلتر ممبران اجازه عبور حدود 95 الی 99 درصد املاح موجود در آب را
                                            نمی دهد. شایان ذکر است فیلتر ممبران، با عنوان فیلتر دستگاه آب شیرین کن صنعتی
                                            نیز شناخته میشود..
                                            <br/>

                                            فیلتر ممبران صنعتی با توجه به این که در مصارف دریایی یا صنعتی استفاده شود
                                            قادر به فیلتر آب با میزان سختی و املاح متفاوت می باشد به این شکل که هر قدر
                                            میزان سختی و املاح در آب بیشتر بوده فشار ناشی از تصفیه آب به فیلتر نیز بیشتر
                                            خواهد بود. به طور کلی انواع فیلتر ممبران صنعتی با توجه به نوع کاربرد که ممکن
                                            است دریایی و یا صنعتی باشد در میزان فشار کاری و میزان آبدهی متفاوت خواهند
                                            بود.

                                        </p>
                                    </div>
                                </div>

                            </div>
                            <div className="lg:w-5/12  w-0  lg:pt-[5rem] pt-0 border-l-main text-left">

                            </div>
                        </div>
                    </div>
                </FullpageSection>
                <FullpageSection>
                    <div ref={refs[2]} className="w-screen lg:mx-[5rem] mx-0 relative">
                        <div className="flex flex-wrap lg:flex-nowrap lg:h-[100vh] h-auto">
                            <div ref={refHeight}
                                className="lg:w-8/12 h-s w-allow resp-2 lg:mr-16 mr-6 lg:ml-0 ml-6 lg:mt-[7rem] tb-lg-0 mt-5 flex flex-col justify-between mb-10"

                            >
                                <div className="header-box">
                                    <div className="relative w-fit">
                                        <h2 className={
                                            activeIndex1 != 3 ?
                                                "lg:font-[700] font-[600] w-fit lg:text-[1.9rem] text-[1.1rem] text-[#3C3C3C]"
                                                : "lg:font-[700] font-[600] w-fit lg:text-[1.9rem] lg:text-[1.1rem] text-[1.3rem] text-[#3C3C3C] animate-rtl-2"
                                        }>
                                            <img className={
                                                activeIndex1 != 3 ? "mb-6 scale-0 transition-all my-image-scale-0" : "mb-6 delay-700 scale-100 my-image-scale-1"}
                                                 src={logoDupont} width={100}
                                                 alt="شرکت DuPont de Nemours" title="شرکت DuPont de Nemours"/>
                                            شرکت "DuPont de Nemours"
                                        </h2>
                                        <span
                                            className={activeIndex1 != 3 ? "animate-ltr" : "animate-ltr run-animate"}></span>
                                    </div>
                                    <div
                                        className={activeIndex1 != 3 ? "body-container f-26 2xl:mt-5 md:mt-0 sm:mt-5 mt-2" : "body-container f-26 2xl:mt-5 md:mt-0 sm:mt-5 mt-2 run-animate-2"}>
                                        <p className="font-[400] text-[1rem] text-[#717171] text-justify my-f-r">
                                            شرکت "DuPont de Nemours" که با نام "DuPont" نيز شناخته میشود، در سال 1802 با
                                            هدف توليد باروت در آمريكا تاسیس شد كه پس از چندی فعالیت خود را در زمینه
                                            صنايع شیمیایی و پلیمری توسعه داد. و در نهايت در سال 2017 با شرکت "DOW" ادغام
                                            شد. توليدات اين شركت در سه شاخه بازرگانی زير در بازار جهانی شناخته ميشوند:
                                            <br/>
                                            کشاورزی(Cortra) ، علم مواد(DOW) و محصولات خاص (DuPont)
                                            <br/>

                                            اما امروزه "DuPont" به عنوان یکی از شناخته شده ترين تولید كنندگان فیلترهای
                                            ممبران اُسمز معکوس و اولترافیلتراسیون در سطح جهان است که محصولات خود را با
                                            نام تجاري "Filmtec" به بازار عرضه ميكند.
                                            <br/>
                                            ‏"Filmtec" توانسته است با توليد انواع ممبران از جمله فيلتر آب دریا و آب های
                                            لب شور در مصارف صنعتی و خانگی به عنوان یکی از اصلی ترين توليد كنندگان در
                                            زمينه تصفيه آب در ميان مصرف كنندگان به شمار بيايد.
                                            <br/>
                                            در ادامه به معرفی فیلترهای ممبران "Filmtec" که بیشترین استفاده را در بین
                                            سایرمدل های دیگر دارد میپردازیم:

                                        </p>
                                        <ul className="disc-row disc-row-2 m-w-660  2xl:mt-5 md:mt-0 sm:mt-5 mt-2">
                                            <li>
                                                <div className="flex items-center justify-between">
                                                    <div>SW30HRLE-400</div>
                                                    <div>BW30 PRO-400 (BW30-400)</div>
                                                </div>
                                                <hr/>
                                            </li>

                                            <li>
                                                <div className="flex items-center justify-between">
                                                    <div>SW30XFR-400/34i</div>
                                                    <div>BW30XHR PRO-440 (BW30HR-440)</div>
                                                </div>
                                                <hr/>
                                            </li>
                                            <li>
                                                <div className="flex items-center justify-between">
                                                    <div>SW30HRLE-440i</div>
                                                    <div>BW30XHR PRO-400/34</div>
                                                </div>
                                                <hr/>
                                            </li>
                                            <li>
                                                <div className="flex items-center justify-between">
                                                    <div>SW30XFR-400/34</div>
                                                    <div>BW30 PRO-365</div>
                                                </div>
                                                <hr/>
                                            </li>
                                            <li>
                                                <div className="flex items-center justify-between">
                                                    <div>SW30XLE-440</div>
                                                    <div>Eco PRO-400</div>
                                                </div>
                                            </li>

                                        </ul>
                                    </div>
                                </div>

                            </div>
                            <div className="lg:w-5/12 w-full  lg:pt-[5rem] pt-0 border-l-main text-left">
                                <img
                                    className={activeIndex1 != 3 ? "mx-auto left-image-animation md:mt-[132px] lg:w-[279px] w-[160px]" : "mx-auto left-image-animation md:mt-[132px] left-image-animation-run lg:w-[279px] w-[160px]"}
                                    src={dupontProduct} width={279} height={506}
                                    alt="محصول DuPont de Nemours"
                                    title="سفير افاق تجارت سبز"/>
                            </div>
                        </div>
                    </div>
                </FullpageSection>
                <FullpageSection>
                    <div ref={refs[3]} className="w-screen lg:mx-[5rem] mx-0 relative">
                        <div className="flex flex-wrap lg:flex-nowrap lg:h-[100vh] h-auto">
                            <div ref={refHeight}
                                className="lg:w-8/12 h-s w-allow resp-2 lg:mr-16 mr-6 lg:ml-0 ml-6 lg:mt-[7rem] tb-lg-0 mt-5 flex flex-col justify-between mb-10"
                            >
                                <div className="header-box">
                                    <div className="relative w-fit">
                                        <h2 className={
                                            activeIndex1 != 4 ?
                                                "lg:font-[700] font-[600] w-fit lg:text-[1.9rem] text-[1.1rem] text-[#3C3C3C]" :
                                                "lg:font-[700] font-[600] w-fit lg:text-[1.9rem] lg:text-[1.1rem] text-[1.3rem] text-[#3C3C3C] animate-rtl-2"
                                        }>
                                            <img className={
                                                activeIndex1 != 4 ? "mb-6 scale-0 transition-all my-image-scale-0" : "mb-6 delay-700 scale-100 my-image-scale-1"}
                                                 src={logoLg} width={100}
                                                 alt="شرکت LGChem" title="شرکت LGChem"/>
                                            شرکت "LGChem"
                                        </h2>
                                        <span
                                            className={activeIndex1 != 4 ? "animate-ltr" : "animate-ltr run-animate"}></span>
                                    </div>
                                    <div
                                        className={activeIndex1 != 4 ? "body-container f-26 2xl:mt-5 md:mt-0 sm:mt-5 mt-2" : "body-container f-26 2xl:mt-5 md:mt-0 sm:mt-5 mt-2 run-animate-2"}>
                                        <p className="font-[400] text-[1rem] text-[#717171] text-justify my-f-r">
                                            شرکت "LGChem" ، یکی دیگر از توليد كنندگان مطرح ممبران های صنعتی و خانگی بوده
                                            كه در سال 1947 در کره جنوبی تاسیس شد و به طور مداوم به پیشرفت قابل توجهی دست
                                            یافته است. یکی از زمینه های فعالیت شرکت "LG" ساخت فیلترهای ممبران تصفیه آب
                                            صنعتی در انواع و مدل های مختلف می باشد. ممبران های تصفیه آب "LG" مانند دیگر
                                            برندها دارای انواع متفاوتی از مدل های ممبران آب لب شور و دریایی می باشد که
                                            توانسته سهم قابل توجهی از بازار جهانی را در دست بگيرد.

                                            <br/>

                                            <br/>
                                            در ادامه به معرفی فیلترهای ممبران "LG" که بیشترین استفاده را در بین سایرمدل
                                            های دیگر دارد میپردازیم:


                                        </p>
                                        <ul className="disc-row m-w-660 mt-5">
                                            <li>
                                                <div className="flex items-center justify-between">
                                                    <div>SW 400 R G2</div>
                                                    <div>BW 400 R</div>
                                                </div>
                                                <hr/>
                                            </li>

                                            <li>
                                                <div className="flex items-center justify-between">
                                                    <div>SW 400 SR</div>
                                                    <div>BW 440 R</div>
                                                </div>
                                                <hr/>
                                            </li>
                                            <li>
                                                <div className="flex items-center justify-between">
                                                    <div>SW 440 GR</div>
                                                    <div>BW 400 AFR</div>
                                                </div>
                                                <hr/>
                                            </li>
                                            <li>
                                                <div className="flex items-center justify-between">
                                                    <div>SW 440 ES</div>
                                                    <div>BW 440 ES</div>
                                                </div>
                                                <hr/>
                                            </li>
                                            <li>
                                                <div className="flex items-center justify-between">
                                                    <div>SW 4040 R</div>
                                                    <div>BW 4040 USE</div>
                                                </div>
                                            </li>

                                        </ul>
                                    </div>
                                </div>

                            </div>
                            <div className="lg:w-5/12 w-full  lg:pt-[5rem] pt-0 border-l-main text-left">

                                <img
                                    className={activeIndex1 != 4 ? "mx-auto left-image-animation md:mt-[132px] lg:w-[279px] w-[160px]" : "mx-auto left-image-animation md:mt-[132px] left-image-animation-run lg:w-[279px] w-[160px]"}
                                    src={lgProduct} width={279} height={506}
                                    alt="محصول DuPont de Nemours"
                                    title="سفير افاق تجارت سبز"/>
                            </div>
                        </div>
                    </div>
                </FullpageSection>
                <FullpageSection>
                    <div ref={refs[4]} className="w-screen lg:mx-[5rem] mx-0 relative">
                        <div className="flex flex-wrap lg:flex-nowrap lg:h-[100vh] h-auto">
                            <div ref={refHeight}
                                className="lg:w-8/12 h-s w-allow resp-2 lg:mr-16 mr-6 lg:ml-0 ml-6 lg:mt-[7rem] tb-lg-0 mt-5 flex flex-col justify-between mb-10"
                            >
                                <div className="header-box">
                                    <div className="relative w-fit">
                                        <h2 className={
                                            activeIndex1 != 5 ?
                                                "lg:font-[700] font-[600] w-fit lg:text-[1.9rem] text-[1.1rem] text-[#3C3C3C]" :
                                                "lg:font-[700] font-[600] w-fit lg:text-[1.9rem] lg:text-[1.1rem] text-[1.3rem] text-[#3C3C3C] animate-rtl-2"
                                        }>
                                            <img className={
                                                activeIndex1 != 5 ? "mb-6 scale-0 transition-all my-image-scale-0" : "mb-6 delay-700 scale-100 my-image-scale-1"}
                                                 src={logoHydranautics} width={150}
                                                 alt="شرکت HYDRANAUTICS" title="شرکت HYDRANAUTICS"/>
                                            شرکت "HYDRANAUTICS"
                                        </h2>
                                        <span
                                            className={activeIndex1 != 5 ? "animate-ltr" : "animate-ltr run-animate"}></span>
                                    </div>
                                    <div
                                        className={activeIndex1 != 5 ? "body-container f-26 2xl:mt-5 md:mt-0 sm:mt-5 mt-2" : "body-container f-26 2xl:mt-5 md:mt-0 sm:mt-5 mt-2 run-animate-2"}>
                                        <p className="font-[400] text-[1rem] text-[#717171] text-justify my-f-r">
                                            شرکت "HYDRANAUTICS" که یکی از زیر مجموعه های شرکت "NITTO DENKO" میباشد که در
                                            سال 1970 در آمریکا برای تولید تخصصي فیلترهای تصفیه آب صنعتی و ممبران های
                                            تصفیه آب اسمز معکوس تاسیس شد.از این شرکت همواره به عنوان يكی از برترين توليد
                                            كنندگان اين زمينه ياد ميشود.
                                            در ادامـــــــه بــــــــه معرفــــی فیلترهای مــمبـــران "HYDRANAUTICS" که
                                            بیشترین استفاده را در بین سایرمدل های دیگر دارد میپردازیم:

                                            <br/>
                                            <br/>

                                            در ادامه به معرفی فیلترهای ممبران "HYDRANUTICS" که بیشترین استفاده را در بین
                                            سایرمدل های دیگر دارد میپردازیم:


                                        </p>
                                        <ul className="disc-row m-w-660 mt-5">
                                            <li>
                                                <div className="flex items-center justify-between">
                                                    <div>LFC3</div>
                                                    <div>CPA 3</div>
                                                </div>
                                                <hr/>
                                            </li>

                                            <li>
                                                <div className="flex items-center justify-between">
                                                    <div>ESPA4</div>
                                                    <div>CPA2-4040</div>
                                                </div>
                                                <hr/>
                                            </li>
                                            <li>
                                                <div className="flex items-center justify-between">
                                                    <div>ESPA1/ESPA2/ESPA2</div>
                                                    <div>CPA5 MAX</div>
                                                </div>
                                                <hr/>
                                            </li>
                                            <li>
                                                <div className="flex items-center justify-between">
                                                    <div>SWC4/SWC4+8040</div>
                                                    <div>CPA6-LD</div>
                                                </div>
                                                <hr/>
                                            </li>
                                            <li>
                                                <div className="flex items-center justify-between">
                                                    <div>SWC</div>
                                                    <div>ESPA2 MAX</div>
                                                </div>
                                            </li>

                                        </ul>
                                    </div>
                                </div>

                            </div>
                            <div className="lg:w-5/12 w-full  lg:pt-[5rem] pt-0 border-l-main text-left">
                                <img
                                    className={activeIndex1 != 5 ? "mx-auto left-image-animation md:mt-[132px] lg:w-[279px] w-[160px]" : "mx-auto md:mt-[132px] left-image-animation left-image-animation-run lg:w-[279px] w-[160px]"}
                                    src={hydranauticsProduct} width={279} height={506}
                                    alt="محصول DuPont de Nemours"
                                    title="سفير افاق تجارت سبز"/>
                            </div>
                        </div>

                    </div>
                </FullpageSection>
                <FullpageSection>
                    <div ref={refs[5]} className="w-screen lg:mx-[5rem] mx-0 relative">
                        <div className="flex flex-wrap lg:flex-nowrap lg:h-[100vh] h-auto">
                            <div ref={refHeight}
                                className="lg:w-8/12 h-s w-allow resp-2 lg:mr-16 mr-6 lg:ml-0 ml-6 lg:mt-[7rem] tb-lg-0 mt-5 flex flex-col justify-between mb-10"
                            >

                                <div className="header-box">
                                    <div className="relative w-fit">
                                        <h2 className={
                                            activeIndex1 != 6 ?
                                                "lg:font-[700] font-[600] w-fit lg:text-[1.9rem] text-[1.1rem] text-[#3C3C3C]" :
                                                "lg:font-[700] font-[600] w-fit lg:text-[1.9rem] lg:text-[1.1rem] text-[1.3rem] text-[#3C3C3C] animate-rtl-2"
                                        }
                                        >
                                            <img className={
                                                activeIndex1 != 6 ? "mb-6 scale-0 transition-all my-image-scale-0" : "mb-6 delay-700 scale-100 my-image-scale-1"}
                                                 src={logoToray} width={150}
                                                 alt="شرکت TORAY" title="شرکت TORAY"/>
                                            شرکت "TORAY"
                                        </h2>
                                        <span
                                            className={activeIndex1 != 6 ? "animate-ltr" : "animate-ltr run-animate"}></span>
                                    </div>
                                    <div
                                        className={activeIndex1 != 6 ? "body-container f-26 2xl:mt-5 md:mt-0 sm:mt-5 mt-2" : "body-container f-26 2xl:mt-5 md:mt-0 sm:mt-5 mt-2 run-animate-2"}>
                                        <p className="font-[400] text-[1rem] text-[#717171] text-justify my-f-r">
                                            شرکت "TORAY" در سال 1926 در ژاپن تاسیس و از سال 1967 به توليد انواع ممبران
                                            صنعتی و خانگی پرداخت. این برند روند تولید بسیار اصولی را برای ممبران های خود
                                            در نظر گرفت.امروزه استفاده از توليدات این شرکت در بسیاری از کشورها به دليل
                                            كيفيت ساخت محصولات و رقابت با بازار جهانی رواج پيدا كرده است.


                                            <br/>
                                            <br/>

                                            در ادامه به معرفی فیلترهای ممبران "TORAY" که بیشترین استفاده را در بین
                                            سایرمدل های دیگر دارد میپردازیم:


                                        </p>
                                        <ul className="disc-row m-w-660 mt-5">
                                            <li>
                                                <div className="flex items-center justify-between">
                                                    <div>TM820H-400</div>
                                                    <div>TM720-400</div>
                                                </div>
                                                <hr/>
                                            </li>

                                            <li>
                                                <div className="flex items-center justify-between">
                                                    <div>TM820-370</div>
                                                    <div>TM720-430</div>
                                                </div>
                                                <hr/>
                                            </li>
                                            <li>
                                                <div className="flex items-center justify-between">
                                                    <div>SU-720R</div>
                                                    <div>TML20-370</div>
                                                </div>
                                                <hr/>
                                            </li>
                                            <li>
                                                <div className="flex items-center justify-between">
                                                    <div>SU-620</div>
                                                    <div>TM820H-370</div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                            </div>
                            <div className="lg:w-5/12 w-full  lg:pt-[5rem] pt-0 border-l-main text-left">

                                <img
                                    className={activeIndex1 != 6 ? "mx-auto left-image-animation md:mt-[132px] lg:w-[279px] w-[160px]" : "mx-auto md:mt-[132px] left-image-animation left-image-animation-run  lg:w-[279px] w-[160px]"}
                                    src={torayProduct} width={279} height={506}
                                    alt="محصول DuPont de Nemours"
                                    title="سفير افاق تجارت سبز"/>
                            </div>
                        </div>

                    </div>
                </FullpageSection>
                <FullpageSection>
                    <div className="center-logo-container relative">
                        <img className="m-auto logo-phone" src={logo} width={400} title="سفير افاق تجارت سبز" alt="سفير افاق تجارت سبز"/>
                    </div>
                </FullpageSection>
            </FullpageContainer>

        </div>


    )
}
