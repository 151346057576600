import {useState, useEffect} from "react";
import {Navigate, Route, Routes} from "react-router-dom";

import "./App.css";
import Home from "./page/Home";
const App = () => {
    return (
        <div className="App">
            <Routes>
                <Route path="/" element={<Home />}/>
            </Routes>
        </div>
    );
};


export default App;
